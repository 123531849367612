import defaultTheme from './theme.default';
 
export const Themes = {
  defaultTheme,
 };

// export const themeConfig = {
//   theme: 'defaultTheme',
// };
export default Themes;
