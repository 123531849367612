import actions from './actions';

const initialState = { 
    mitrotech_api_url :  'http://customer.mitrotech.net/cdn/api',
    mitrotech_api_media : 'http://customer.mitrotech.net/cdn/media',
    customer_id_com_company:'1',
} 

export default function config  (state = initialState,action)  { 
    switch(action.type) {
          
 
         
        //  case actions.LOGGED_IN:  
        //  return  {...state, isLoggedIn:true}

        //  case actions.ADD_ID_COM_USER:  
        //  return  {...state, id_com_user:action.id_com_user}
         
       

         default:
               return state
    }
}

;
