import actions from './actions';

const initialState = { 
    api_url : 'https://ulusalecza.mitrodev.app/api',
    netsis_url : 'https://ulusalecza.mitrodev.app/netsis', 
    api_media : 'https://ulusalecza.mitrodev.app/media',

    // netsis_url : 'http://localhost/netsis',
    // api_url : 'http://localhost/api',
    // api_media : 'http://localhost/media',
    isLoggedIn:false,
    id_com_user :'',
    id_com_company:'',
    id_com_company_type:'',
    loginToken:'',
    id_com_user_type:'',
    email:'',
    password:'',
    username:'',
    id_com_manager:'',
    id_com_department:'',
    id_com_company_name:'',
    company_short_name:'',
    themeName:'defaultTheme',
    callopseSidebar:false,
    components:[],
    component_auth:'',
    elapsed_time:'',
    id_com_group:'',
    plasiyer_kodu:'',
} 

export default function config  (state = initialState,action)  { 
    switch(action.type) {
          
        case actions.LOG_OUT:  
          window.location.href = '/';
          localStorage.clear();
          return  state=initialState;

         case actions.ADD_LOGIN_TOKEN:  
         return  {...state, loginToken:action.loginToken}
         
         case actions.LOGGED_IN:  
         return  {...state, isLoggedIn:true}

         case actions.ADD_ID_COM_GROUP:  
         return  {...state, id_com_group:action.id_com_group}

         case actions.ADD_ID_COM_USER:  
         return  {...state, id_com_user:action.id_com_user}
         
         case actions.ADD_EMAIL:  
         return  {...state, email:action.email}
         
         case actions.ADD_PASSWORD:  
         return  {...state, password:action.password}

         case actions.ADD_ELAPSED_TIME:  
         return  {...state, elapsed_time:action.elapsed_time}

         case actions.ADD_ID_COM_COMPANY:  
         return  {...state, id_com_company:action.id_com_company}  

         case actions.ADD_ID_COM_COMPANY_NAME:  
         return  {...state, id_com_company_name:action.id_com_company_name} 

         case actions.ADD_USERNAME:  
         return  {...state, username:action.username}  

         case actions.ADD_ID_COM_USER_TYPE:  
         return  {...state, id_com_user_type:action.id_com_user_type}

         case actions.ADD_ID_COM_DEPARTMENT:  
         return  {...state, id_com_department:action.id_com_department}

         case actions.ADD_MANAGER:  
         return  {...state, id_com_manager:action.id_com_manager}  

         case actions.CALLOPSE_SIDEBAR:  
         return  {...state, callopseSidebar:!state.callopseSidebar} 


         case actions.GET_COMPONENTS:  
         return  {...state, components:action.components}

         case actions.ADD_COMPONENT_AUTH:  
         return  {...state, component_auth:action.component_auth}    

         case actions.ADD_ID_COM_COMPANY_TYPE:  
         return  {...state, id_com_company_type:action.id_com_company_type}    

         case actions.ADD_COMPANY_SHORT_NAME:
            return  {...state, company_short_name:action.company_short_name}    

        case actions.ADD_PLASIYER_KODU:
            return  {...state, plasiyer_kodu:action.plasiyer_kodu}  
          default:
              
               return state
    }
}

;
