import {createStore} from 'redux';
import allReducers from './boot-reducer';
import moment  from 'moment';

var jwt = require('jsonwebtoken');

function saveToLocalStorage (state) { 
      const scrt = state.config.loginToken;
      try {
            const serializedState = JSON.stringify(state);
            const timer =60*120
            const expt = moment(new Date()).add(timer,'s');
            localStorage.setItem('expires',expt);
            var tkn_state = jwt.sign({ serializedState }, scrt,{ expiresIn:  timer,algorithm:'HS512'});
            localStorage.setItem('stt',tkn_state)
            localStorage.setItem('mtr',state.config.loginToken)
      }
      catch (e) { 
            console.log(e);
      }
}

function loadFromLocalStorage(){
      try  {           

                  const serializedState =  localStorage.getItem('stt');
                  const scrt  = localStorage.getItem('mtr');

                        jwt.verify(serializedState, scrt, function(err, decoded) {
                              if(err) {localStorage.clear()} 
                        });

                  const tkn_state =  jwt.verify(serializedState, scrt);

                  if(serializedState===null)
                              {  return undefined  }
                        else
                              {  return JSON.parse(tkn_state.serializedState)  }
            }
      catch (e)
            { 
            console.log(e);
            return undefined
            }
}

const persistedState = loadFromLocalStorage();
const store = createStore(allReducers,persistedState)//window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) //
store.subscribe(()=>saveToLocalStorage(store.getState()))

export default store;