import React, { lazy, Suspense } from 'react';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import Loader from '../utilities/loader/loader';
import mapStateToProps from '../redux/mapStateToProps';

const PrivateContents = lazy(() => import('../layout/private/Layout.private'));
const PublicContents = lazy(() => import('../layout/public/Layout.public'));




const  PrivateRoute = ({  children,path , ...rest}) =>{
  const isLoggedIn =  useSelector(state => state.config.isLoggedIn);
       return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect

            to={{
              pathname: '/auth/signin',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const  PublicRoute = ({ children, ...rest }) => {
  return (
     <Route   {...rest} render={()=>children}  />
    );
}

const  Routes = ()=>{
  
   return (
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
                <PrivateRoute path="/portal" >
                    <PrivateContents   /> 
                </PrivateRoute>
                 <PublicRoute path='/'> 
                    <PublicContents/>
                </PublicRoute>
          </Switch>
        </Router>
      </Suspense>
  );
}
export default connect(mapStateToProps)(Routes);